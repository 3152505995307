// Highlight effect function
$.fn.highlight = function(color, duration) {
    // Set default values to parameters
    color = typeof color !== 'undefined' ? color : '#ff9';
    duration = typeof duration !== 'undefined' ? duration : 500;

    // Set the box-shadow css property in advance to works properly
    this.css('box-shadow', '0 0 0 0' + color);

    var that = this;
    var background_color = this.css('background-color');

    this.stop().animate({
        backgroundColor: color,
        boxShadow: '0 0 15px 0 ' + color,
    }, duration, 'swing', function() {
        that.stop().animate({
            backgroundColor: background_color,
            boxShadow: '0 0 0 0 ' + color,
        }, duration);
    });
};

/**
 * Open the simple modal view.
 */
var showSimpleModal = function(url, header, error_message) {
    $('#simple-modal .modal-title').text(header);
    $('#simple-modal .alert-message').text(error_message);
    $('#simple-modal .loading').removeClass('hidden');
    $('#simple-modal .fail').addClass('hidden');
    $('#simple-modal .data').html('');
    $('#simple-modal').modal('show');

    $.get(url).success(function(data) {
        $('#simple-modal .loading').addClass('hidden');
        $('#simple-modal .data').html(data);
    }).fail(function() {
        $('#simple-modal .loading').addClass('hidden');
        $('#simple-modal .fail').removeClass('hidden');
    });
};

/**
 * Open the modal view on the visas list.
 */
var showModal = function(url, header, error_message) {
    $('#visa-modal .modal-title').text(header);
    $('#visa-modal .alert-message').text(error_message);
    $('#visa-modal .loading').removeClass('hidden');
    $('#visa-modal .fail').addClass('hidden');
    $('#visa-modal .data').html('');
    $('#visa-modal').modal('show');

    $.get(url).success(function(data) {
        $('#visa-modal .loading').addClass('hidden');
        $('#visa-modal .data').html(data);
    }).fail(function() {
        $('#visa-modal .loading').addClass('hidden');
        $('#visa-modal .fail').removeClass('hidden');
    });
};

$('document').ready(function() {

    //Checking version browser supported
    if (! Dropzone.isBrowserSupported()) {
        sweetAlert({
            title: app_translations.no_version_supported, 
            text: app_translations.no_version_supported_text, 
            type: "warning",
            html: true,
        });
        $('.btn-success').addClass('hide');
    }

    //Control element shoe/hide into sidebar
    var toogleElementsSideBar = function(operator) {
        if ($('body').hasClass('sidebar-collapse') == operator) {
            $('.toogle-with-sidebar').addClass('hide');
        } else {
            $('.toogle-with-sidebar').removeClass('hide');
        }
    }

    toogleElementsSideBar(true);

    // When toggling the sidebar sends an ajax call to set
    // if the sidebar is open or close on a cookie
    $('.sidebar-toggle').click(function() {
        $.get('/set_cookies', {
            data: {
                collapse: $('body').hasClass('sidebar-collapse'),
            },
        });
        toogleElementsSideBar(false);
    });

    //Create form delete on fly
    var createFormDelete = function(action) {
        var form = [];
        form.push('<form id="deleteFrmDinamic" method="POST" action="'+ action +'">');
        form.push('<input name="_method" type="hidden" value="DELETE">');
        form.push('<input name="_token" type="hidden" value="'+ $('meta[name=token]').attr('content') +'">');
        form.push('</form>');
        $('body').append(form.join('\n'));
        $('#deleteFrmDinamic').submit();
    };

    // When a link with the class delete-button is clicked will send
    // a form to delete the related resource, but first user must confirm the action
    $('a.delete-button').click(function(ev) {
        ev.preventDefault();
        var action = $(this).attr('href');
        swal({
            title: app_translations.sure_to_delete_title,
            text : app_translations.sure_to_delete,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: app_translations.yes,
            cancelButtonText: app_translations.no
        }, function(isConfirm){
            if (isConfirm) {
                createFormDelete(action);
            }
        });
    });

    $('a.reject-button').click(function(ev) {
        ev.preventDefault();
        let action = $(this).attr('href');
        swal({
            title: app_translations.sure_to_reject_title,
        text : app_translations.sure_to_reject,
        type: "warning",
            showCancelButton: true,
            confirmButtonText: app_translations.yes,
        cancelButtonText: app_translations.no
    }, function(isConfirm){
            if (isConfirm) {
                createFormDelete(action);
            }
        });
    });

    // Btn save cookie
    $('.btn-cookie-collapse').click(function() {
        //TODO: raulduran
        $.get('/set_cookies', {
            data: {
                searcher_collapse: $(this).find('i').hasClass('fa-minus'),
            },
        });
        $('.box-body').attr('style', '');
    });

    //Disbled buttons on submit form
    $('form').submit(function() {
        $('.btn-submit,.btn-success', this).prop('disabled', true);
    });

    // Reset the form fields
    $('.clear-form').click(function() {
        $('.search-form :input').prop('disabled', true);
        $('.search-form').submit();
    });

    // Initialize the date selectors
    $('.datepicker').datepicker({
        autoclose: true,
        format: 'yyyy-mm-dd',
        language: 'es',
        weekStart: 1,
    });

    // Initialize the time selectors
    $('.timepicker').timepicker({
        defaultTime: false,
        minuteStep: 5,
        secondStep: 5,
        showInputs: false,
        showMeridian: false,
        showSeconds: true,
    });

    if (typeof(tinymce) != "undefined") {
        tinymce.init({
            language: 'es',
            selector: "textarea.editor",
            plugins: [
                 "advlist autolink link image lists charmap print preview hr anchor pagebreak",
                 "searchreplace wordcount visualblocks visualchars insertdatetime media nonbreaking",
                 "table contextmenu directionality emoticons paste textcolor responsivefilemanager code"
            ],
            toolbar1: "undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | styleselect",
            toolbar2: "| responsivefilemanager | link unlink anchor | image media | forecolor backcolor  | print preview code ",
            image_advtab: true,
            external_filemanager_path: "/filemanager/",
            filemanager_title: "Administrador de ficheros",
            filemanager_access_key: $('[name="access_key"]').attr('content'),
            external_plugins: {
                "filemanager" : "/filemanager/plugin.min.js"
            },
            relative_urls : false,
            remove_script_host : false,
            convert_urls : true
        });
    }

    // Initialize the select2 selectors
    $('select.select2').select2({
        minimumResultsForSearch: 10,
    });

    // Initialize the select2 for municipalities fields because have
    // several records and its heavy to load them at start
    $('select.select2.municipality').select2({
        ajax: {
            url: '/api/municipalities',
            delay: 250,
            data: function(parameters) {
                var query = {
                    name: parameters.term,
                };

                if (this.attr('rel') && $('select#' + this.attr('rel')).val()) {
                    query.province_id = $('select#' + this.attr('rel')).val();
                }

                return query;
            },
            processResults: function(data) {
                var results = [];

                for (var key in data) {
                    var children = [];

                    for (var key_child in data[key]) {
                        children.push({
                            id: key_child,
                            text: data[key][key_child],
                        });
                    }

                    results.push({
                        text: key,
                        children: children,
                    });
                }

                return {
                    results: results,
                };
            },
        },
    });

    // Hack for the forms with tabs.
    // When the form is submitted if there is an input with errors
    // this will set as active the tab were the input is.
    $('.form-tabbed [type="submit"]').click(function() {
        var errors = $('.form-tabbed :invalid');

        if (errors.length) {
            var tab = $(errors[0]).closest('.tab-pane').attr('id');
            $('.form-tabbed a[href="#' + tab + '"]').tab('show');
        }

        return true;
    });

    //Alert Autohide
    setTimeout(function() {
        $(".alert-autohide").slideUp();
    }, 5000);

    // Configurate dropzone plugin
    Dropzone.autoDiscover = false;

    if ($('.dropzone-uploader').length) {
        var dropzones = [];

        $('.dropzone-uploader').each(function(uploader) {
            var uploader = '#' + this.id,
                uploaderCamelCased = this.id.replace(/\W+(.)/g, function(x, chr) {
                    return chr.toUpperCase();
                });

            Dropzone.options[uploaderCamelCased] = {
                acceptedFiles: '.pdf',
                autoProcessQueue: true,
                maxFiles: 1,
                maxFilesize: dropzone_max_file_size,
                previewTemplate: $(uploader + ' .dropzone-preview-template').html(),
                uploadMultiple: false,

                // Translations
                dictDefaultMessage: app_translations.dropzone_default_message,
                dictFallbackMessage: app_translations.dropzone_fallback_message,
                dictFallbackText: app_translations.dropzone_fallback_text,
                dictFileTooBig: app_translations.dropzone_file_too_big,
                dictInvalidFileType: app_translations.dropzone_invalid_file_type,
                dictResponseError: app_translations.dropzone_response_error,
                dictCancelUpload: app_translations.dropzone_cancel_upload,
                dictCancelUploadConfirmation: app_translations.dropzone_cancel_upload_confirmation,
                dictRemoveFile: app_translations.dropzone_remove_file,
                dictMaxFilesExceeded: app_translations.dropzone_max_files_exceeded,

                init: function() {
                    var uploadedFile = null;

                    // Remove the previous uploaded file
                    this.on('addedfile', function(file) {
                        if (uploadedFile) {
                            this.removeFile(uploadedFile);
                        }

                        $(uploader + ' .dropzone-file').val('');
                        $(uploader + ' .dropzone-filename').val(file.name);
                        uploadedFile = file;

                        $('.btn-dropzone').attr('disabled', true);
                    });

                    // Set the uploaded file name on hidden input
                    this.on('success', function(file, response) {
                        uploadedFile = file;

                        if (file.accepted) {
                            $(uploader + ' .dropzone-file').val(response);
                            $('.btn-dropzone').attr('disabled', false);
                        }
                    });

                    // Update the total progress bar
                    this.on("totaluploadprogress", function(progress) {
                        $(uploader + ' .info-box-progress').html(parseInt(progress, 10) + "%");
                        $('.btn-dropzone').attr('disabled', true);
                    });
                },
            };

            dropzones.push(new Dropzone(uploader, { url: $(uploader).data('url') }));
        });
    }

    // Confirmation to visa a request
    $('.generate-visa').click(function() {
        return confirm(app_translations.confirm_generate_visa);
    });

    // Confirmation to add quality to a request
    $('.generate-quality').click(function() {
        if (confirm(app_translations.confirm_generate_quality)) {
            this.href += '?force_quality_invoice=' + (confirm(app_translations.ask_force_quality_invoice) ? 1 : 0);
            return true;
        }

        return false;
    });

    // Show a modal view with the visa log
    $('.show-simple-modal').click(function(ev) {
        ev.preventDefault();
        var title = $(this).attr('data-title');
        showSimpleModal(this.href, title + ' ' + this.rel, app_translations.error_loading_log);
    });

    // Show a modal view with the visa log
    $('.show-visa-log').click(function(ev) {
        ev.preventDefault();
        showModal(this.href, app_translations.request_log + ' ' + this.rel, app_translations.error_loading_log);
    });

    // Show a modal view with the visa quality log
    $('.show-visa-quality-log').click(function(ev) {
        ev.preventDefault();
        showModal(this.href, app_translations.request_quality_log + ' ' + this.rel, app_translations.error_loading_log);
    });

    // Show a modal view with the visa data
    $('.show-visa').click(function(ev) {
        ev.preventDefault();
        showModal(this.href, app_translations.visa_request + ' ' + this.rel, app_translations.error_loading_visa_data);
    });

    // Show a modal view with the visa participants
    $('.show-visa-participants').click(function(ev) {
        ev.preventDefault();
        showModal(
            this.href,
            app_translations.visa_participants + ' ' + this.rel,
            app_translations.error_loading_visa_data
        );
    });

    // Show a modal view with the dossier's reports
    $('.show-dossier-reports').click(function(ev) {
        ev.preventDefault();
        showModal(
            this.href,
            app_translations.dossier_reports + ' ' + this.rel,
            app_translations.error_loading_visa_data
        );
    });

    // Show a modal view with the visa's reports
    $('.show-reports').click(function(ev) {
        ev.preventDefault();
        showModal(
            this.href,
            app_translations.visa_request + ' ' + this.rel,
            app_translations.error_loading_visa_data
        );
    });

    // Javascript to enable link to tab
    var hash = document.location.hash;
    if (hash) {
        $('.nav-tabs a[href='+hash+']').tab('show');
    }

    $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    });

});
